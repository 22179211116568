import { Controller } from "stimulus"
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import XHRUpload from "@uppy/xhr-upload"
import toastr from '../modules/toastr'
import moment from 'moment'
import flash from '../constants/flash'
import { reloadCurrentPage } from "../utils/turbolinks"

export default class extends Controller {
  connect() {
    this.initializeUppy()
    this.onUppyFileEditStart()
    this.onUppyFileEditComplete()
    this.onUppyUploadComplete()
    this.displayFlashMessage()
  }

  initializeUppy() {
    this.uppy = Uppy({
      debug: false,
      autoProceed: false,
      restrictions: {
        maxNumberOfFiles: 50,
        minNumberOfFiles: 1,
      }
    }).use(Dashboard, {
      trigger: '.UppyModalOpenerBtn',
      inline: true,
      target: '.DashboardContainer',
      replaceTargetContent: true,
      showProgressDetails: true,
      height: 550,
      browserBackButtonClose: true,
      metaFields: this.metaFields,
    }).use(XHRUpload, {
      endpoint: this.url,
      bundle: false,
      timeout: 0,
      headers: { "X-CSRF-Token": this.csrfToken }
    }) 
  }

  onUppyFileEditStart() {
    this.uppy.on('dashboard:file-edit-start', () => {   
      this.interval = setInterval(() => {  
        if (this.projectDate && this.expirationDate) {
          this.initializeDatePickerFields([this.projectDate, this.expirationDate])
          this.setDatePickerFields([this.projectDate, this.expirationDate])
          this.toggleReminderCheckbox()
        }
      }, 100)
    })
  }

  onUppyFileEditComplete() {
    this.uppy.on('dashboard:file-edit-complete', () => {  
      clearInterval(this.interval)
    })
  }

  onUppyUploadComplete() {
    this.uppy.on('complete', result => {
      if (!result.failed.length) {
        reloadCurrentPage(flash.DOCUMENTS_UPLOADED_SUCCESS)
      }
    })
  }

  displayFlashMessage() {
    if (this.flashMessage) {
      toastr.success(this.flashMessage)
      sessionStorage.removeItem('flash')
    }
  }

  initializeDatePickerFields(fields) {
    fields.forEach(field => field.setAttribute('data-controller', 'date-picker'))
  }

  toggleReminderCheckbox() {
    if (this.expirationDate.value) {
      this.reminderCheckBox.closest('fieldset').style.display = "block"
    } else {
      this.reminderCheckBox.closest('fieldset').style.display = "none"
    }
  }

  setDatePickerFields(fields) {
    fields.forEach(field => {
      if (field.value && field.nextElementSibling) {
        field.nextElementSibling.value = moment(field.value).format('MMMM D, YYYY')
      }
    });
  }

  get reminderCheckBox() {
    return $('#reminder_option_checkbox')[0]
  }
  
  get formContainer() {
    return $(".uppy-Dashboard-FileCard-info")[0]
  }
  
  get projectDate() {
    return $('#uppy-Dashboard-FileCard-input-project_date')[0]
  }

  get expirationDate() {
    return $('#uppy-Dashboard-FileCard-input-expiration_date')[0]
  }

  get metaFields() {
    return [
      { id: 'name', name: 'Name', placeholder: 'file name' },
      { id: 'project_date', name: 'Project Date' },
      { id: 'expiration_date', name: 'Expiration Date' },
      {
        id: 'reminder_option',
        name: 'Create Expiration Date Reminder',
        render: ({ value, onChange }, h) => {
          return h('input', {
            type: 'checkbox',
            id: 'reminder_option_checkbox',
            onChange: (ev) => onChange(ev.target.checked ? 'true' : 'false'),
            defaultChecked: value === 'true'
          })
        }
      }
    ]
  }
  
  get url() {
    return this.data.get('url')
  }

  get flashMessage() {
    return sessionStorage.getItem('flash')
  }

  get csrfToken() {
    return document.querySelector('meta[name="csrf-token"]').content
  }
}
