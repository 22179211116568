import { Controller } from "stimulus"
import axios from "../modules/axios"

export default class extends Controller {
  connect() {
    $(this.element).sortable({
      axis: 'y',
      cancel: ".ui-state-disabled",
      update: () => {
        axios.patch(this.url, {
          folder: this.folderIds
        })
      }
    })
  }

  get folderIds() {
    return $(this.element).find('li').map(function() {
      return $(this).attr('data-folder-id')
    }).toArray()
  }

  get url() {
    return this.data.get('url')
  }
}
