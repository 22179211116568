import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).tablesorter()
  }

  switchSortIcon(event) {
    const $target = $(event.target)
    const $icon = $target.find('i')

    $icon.closest('tr').find('.fa-caret-down').not($icon).removeClass('fa-caret-down fa-rotate-180')
    
    if ($icon.hasClass('fa-caret-down')) {
      $icon.toggleClass('fa-rotate-180')
    } else {
      $icon.addClass('fa-caret-down fa-rotate-180')
    }
  }
}
