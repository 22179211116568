import { Controller } from "stimulus"
import axios from '../modules/axios'
import toastr from '../modules/toastr'
import flash from '../constants/flash'
import { reloadCurrentPage } from "../utils/turbolinks"

export default class extends Controller {
  static targets = ["email", "accountId", "phone"]

  connect() {
    this.displayFlashMessage()
  }

  async invite(event) {
    event.preventDefault()

    const url = event.target.dataset.url
    
    if (!this.email) return toastr.error(flash.BLANK_EMAIL)

    await axios.post(url, {
      user: {
        email: this.email,
        account_id: this.accountId,
      }
    })

    reloadCurrentPage(flash.INVITE_SUCCESS)
  }

  async updateEmail(event) {
    event.preventDefault()
    
    if (!this.email) return toastr.error(flash.BLANK_EMAIL)

    const url = event.target.dataset.url

    await axios.patch(url, {
      user: {
        email: this.email,
      }
    })

    reloadCurrentPage(flash.UPDATE_EMAIL_SUCCESS)
  }

  async updatePhoneNumber(event) {
    event.preventDefault()

    const url = event.target.dataset.url

    await axios.patch(url, {
      user: {
        phone_number: this.phoneNumber,
      }
    })

    reloadCurrentPage(flash.UPDATE_PHONE_NUMBER_SUCCESS)
  }

  async updatePassword(event) {
    const $element = $(this.element)
    const $form = $element.find('form')
    const url = event.target.dataset.url

    if (!$form[0].checkValidity()) return

    event.preventDefault()

    await axios.patch(url, $form.serialize())

    reloadCurrentPage(flash.UPDATE_PASSWORD_SUCCESS)
  }

  displayFlashMessage() {
    if (this.flashMessage) {
      toastr.success(this.flashMessage)
      sessionStorage.removeItem('flash')
    }
  }

  get email() {
    return this.emailTarget.value
  }

  get phoneNumber() {
    return this.phoneTarget.value
  }

  get accountId() {
    return this.accountIdTarget.value
  }

  get flashMessage() {
    return sessionStorage.getItem('flash')
  }
}
