import { Controller } from "stimulus"

export default class extends Controller {
 blur(event) {
  const { value } = event.target
  const $target = $(event.target)

   if (value.length > 0) {
     $target.addClass('has-green-border')
   } else {
    $target.removeClass('has-green-border')
   }
 }
}
