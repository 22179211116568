import { Controller } from "stimulus"
import toastr from '../modules/toastr'

export default class extends Controller {
  connect() {
    if (this.successMessage) {
      toastr.success(this.successMessage)
    }

    if (this.errorMessages) {
      try {
        JSON.parse(this.errorMessages).forEach(message => {
          toastr.error(message)
        })
      } catch (e) {
        toastr.error(this.errorMessages)
      }
    }
  }

  disconnect() {
    this.clearFlash()
    toastr.remove()
  }

  clearFlash() {
    this.data.set('success', '')
    this.data.set('alert', '')
  }

  get successMessage() {
    return this.data.get('success')
  }

  get errorMessages() {
    return this.data.get('alert')
  }
}
