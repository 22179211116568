export default {
  INVITE_SUCCESS: 'Invite sent successfully',
  UPDATE_EMAIL_SUCCESS: 'Your email address has been updated successfully',
  UPDATE_PHONE_NUMBER_SUCCESS: 'Your phone number has been updated successfully',
  UPDATE_PASSWORD_SUCCESS: 'Your password has been updated successfully',
  DOCUMENTS_UPLOADED_SUCCESS: 'Document(s) uploaded successfully',
  DOCUMENT_EDIT_SUCCESS: 'Document edited successfully',
  DOCUMENT_MOVE_SUCCESS: 'Document moved successfully',
  DOCUMENT_SHARE_SUCCESS: 'Document shared successfully',
  REMINDER_ADDED_TO_CALENDAR: 'Reminder has been added to the calendar',
  REMINDER_REMOVED_FROM_CALENDAR: 'Reminder has been removed from the calendar',
  BLANK_EMAIL: "Email can't be empty",
  BLANK_DOCUMENT_NAME: "Document name can't be empty",
  BLANK_PROJECT_DATE: "Project date can't be empty",
  BLANK_REMINDER_NAME: "Reminder name can't be empty",
  BLANK_STARTING_DATE: "Starting at date can't be empty",
  BLANK_DOCUMENT_LIBRARY: "You must select a document library",
  NULL_PARTICIPANTS: "You must include at least a participant to send a reminder",
};
