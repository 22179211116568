import { Controller } from "stimulus"
import axios from '../modules/axios'
import toastr from '../modules/toastr'
import flash from "../constants/flash"
import { reloadCurrentPage } from "../utils/turbolinks"

export default class extends Controller {
  static targets = [ "document", "filename", "email", "customMessage", "yearDropdown", "expirationDateReminder" ]

  connect() {
    this.initializeSelectize()
    this.displayFlashMessage()
  }

  disconnect() {
    this.destroySelectize()
  }
  
  async edit(event) {
    event.preventDefault()
    
    const url = event.target.dataset.url
    const $modal = $(this.element).find('.modal.is-active')
    const name = $modal.find('#name').val()
    const project_date = $modal.find('#document_project_date').val()
    const expiration_date = $modal.find('#document_expiration_date').val()
    const reminder_option = $modal.find('#expiration_date_reminder').is(':checked')
    
    if (!name) return toastr.error(flash.BLANK_DOCUMENT_NAME)
    if (!project_date) return toastr.error(flash.BLANK_PROJECT_DATE) 

    await axios.patch(url, {
      document: {
        name,
        project_date,
        expiration_date,
        reminder_option
      }
    })

    reloadCurrentPage(flash.DOCUMENT_EDIT_SUCCESS)
  }

 move(event) {
    event.preventDefault()
    const $target = $(event.target)
    const url = $target.data('url')
    const documentId = $target.data('document-id')

    const $modal = $(this.element).find('.modal.is-active')

    if (!this.documentLibraryId) return toastr.error(flash.BLANK_DOCUMENT_LIBRARY)

    axios.patch(url, {
      document: {
        folder_id: this.documentLibraryId,
        building_id: this.buildingId,
      }
    })

    $modal.parent().find(`tr[data-id=${documentId}]`).remove()
    $modal.removeClass('is-active')

    toastr.success(flash.DOCUMENT_MOVE_SUCCESS)
  }

  share(event) {
    const email = this.emailTarget.value
    const customMessage = this.customMessageTarget.value
    const url = event.target.dataset.url
    const $modal = $(this.element).find('.modal.is-active')

    if(!email) return toastr.error(flash.BLANK_EMAIL)

    axios.post(url, {
      shared_document: {
        shared_email: email,
        custom_message: customMessage
      }
    })

    $modal.removeClass('is-active')
    toastr.success(flash.DOCUMENT_SHARE_SUCCESS)

    this.customMessageTarget.value = ''
    this.emailTarget.value = ''
  }

  search(event) {
    const $target = $(event.target)
    const $table = $target.closest("div[data-controller=documents]").find('table')
    
    if (event.which == 13) {
      $table.find('tbody tr').each(function() {
        const $tableRow = $(this)
        if ($tableRow.text().toLowerCase().indexOf(event.target.value) === -1) {
          $tableRow.hide()
        } else {
          $tableRow.show()
        }
      })
    }
  }

  toggleReminderCheckBox(event) {
    if (event.target.value) {
      this.expirationDateReminderTarget.style.display = "block"
    } else {
      this.expirationDateReminderTarget.style.display = "none"
    }
  }

  filterByYear(year) {
    this.documentTargets.forEach(target => {
      const $target = $(target)

      if (year && target.dataset.year !== year) {
        $target.hide()
      } else {
        $target.show()
      }
    })
  }

  initializeSelectize() {
    if (this.hasYearDropdownTarget) {
      $(this.yearDropdownTarget).selectize({
        allowEmptyOption: true,
        create: false,
        onChange: value => {
          this.filterByYear(value)
        }
      })  
  
      $('#year-selectized').prop('readonly', true)
    }
  }

  destroySelectize() {
    if (this.hasYearDropdownTarget) this.yearDropdownTarget.selectize.destroy()
  }

  displayFlashMessage() {
    if (this.flashMessage) {
      toastr.success(this.flashMessage)
      sessionStorage.removeItem('flash')
    }
  }

  get flashMessage() {
    return sessionStorage.getItem('flash')
  }

  get buildingId() {
    return $(this.element).find('.selected').attr('data-building-id')
  }

  get documentLibraryId() {
    return $(this.element).find('.selected').attr('data-document-library-id')
  }

  get filename() {
    return $(this.element).find('.modal.is-active').find('input').val()
  }
}
