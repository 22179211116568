import { Controller } from "stimulus"
import axios from '../modules/axios'

export default class extends Controller {
  async open(event) {
    event.preventDefault()
    const $element = $(this.element)
    const $modal = $element.closest('.container').find('.move-modal')
    const $moveButton = $modal.find('.button.move-btn')
    const title = this.data.get('title')
    const url = this.data.get('url')
    const documentId = this.data.get('document-id')

    const { data: { data } } = await axios.get('/')

    $moveButton.attr('disabled', true)
    $moveButton.data('url', url)
    $moveButton.data('document-id', documentId)
    $modal.find('.modal-card-title').text(title)
    $modal.find('.modal-card-body').empty().append(this._folderList(data))
    $modal.find('#back-btn').data('history', [{title, url: '/'}]) 
    $modal.addClass('is-active')
  }

  select(event) {
    event.preventDefault()
    const $target = $(event.currentTarget)
    const $modal = $(this.element)
    const folderType = $target.attr('data-type')

    if(!$target.hasClass('selected') && this._isDocumentLibrary(folderType)) {
      $modal.find('.move-btn').removeAttr('disabled')
    } else {
      $modal.find('.move-btn').prop('disabled', true)
    }
    $target.parent().find('.selected').not($target).removeClass('selected')
    $target.toggleClass('selected')
  }

  async load(event) {
    event.preventDefault()
    event.stopPropagation()

    const $target = $(event.currentTarget)
    const $modal = $(this.element)
    const url = this._getUrl($target)
    const title = this._getTitle($target)
    const $backButton = $modal.find('#back-btn')
    const history = $backButton.data('history')
    const lastVisitedUrl = history[history.length - 1].url

    const { data: { data } } = await axios.get(url)

    $backButton.attr('href', lastVisitedUrl)
    history.push({title, url})
    $backButton.removeClass('is-hidden')

    $modal.find('.modal-card-body').empty().append(this._folderList(data))
    $modal.find('.modal-card-title').text(title)
  }

  async back(event) {
    event.preventDefault()

    const $modal = $(this.element)
    const history = $modal.find('#back-btn').data('history')

    history.pop()

    const {url, title} = history[history.length - 1]
    const { data: { data } } = await axios.get(url)

    if (history.length == 1) $modal.find('#back-btn').addClass('is-hidden')
    $modal.find('.modal-card-title').text(title)
    $modal.find('.modal-card-body').empty().append(this._folderList(data))
  }

  _folderList = folders => folders.map(folder => this._folderItem(folder)).join('')

  _folderItem = folder =>
    this._isDocumentLibrary(folder.attributes.type)
      ? this._renderDocumentLibrary(folder)
      : this._renderFolder(folder)
  
  _dblClickEvent = $target => !$target.attr('href') 

  _isDocumentLibrary = folderType => folderType === 'DocumentLibrary'

  _getUrl = ($target) => this._dblClickEvent($target) ? $target.find('.load').attr('href') : $target.attr('href')
  
  _getTitle = ($target) => {
    const $columns = this._dblClickEvent($target) ? $target.find('.columns') : $target.closest('.columns')
    return $columns.find('.folder-name').text().trim()
  }

  _renderFolder = folder =>
    `<div class="box" data-action="click->move-modal#select dblclick->move-modal#load" data-type=${folder.attributes.type}>
      <div class="columns">
        <div class="column is-11">
          <i class="icon ${folder.attributes.type} is-medium"></i>
          <span class="folder-name">${folder.attributes.name}<span>
        </div>
        <div class="column">
          <a href=${folder.attributes.url} class="load" data-action="click->move-modal#load">
            <i class="fa fa-chevron-right is-invisible"></i>
          </a>
        </div>
      </div>
    </div>`
  
  _renderDocumentLibrary= folder => 
    `<div class="box" data-action="click->move-modal#select" data-type=${folder.attributes.type} data-document-library-id=${folder.id} data-building-id=${folder.attributes.building_id}>
      <i class="icon document-library is-medium"></i>
      <span class="folder-name">${folder.attributes.name}<span>
    </div>`
}
