import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "video", "document", "submit" ] 

  selectType(event) {
    const value = event.target.value

    if (value == "PDF") {
      this.documentTarget.classList.remove("is-hidden")
      this.videoTargets.forEach(video => video.classList.add("is-hidden"))
      this.submitTarget.disabled = false
    } else if (value == "Video") {
      this.documentTarget.classList.add("is-hidden")
      this.videoTargets.forEach(video => video.classList.remove("is-hidden"))
      this.submitTarget.disabled = false
    } else {
      this.documentTarget.classList.add("is-hidden")
      this.videoTargets.forEach(video => video.classList.add("is-hidden"))
      this.submitTarget.disabled = true
    }
  }
}
