import { Controller } from "stimulus"
import moment from 'moment'
import axios from '../modules/axios'

export default class extends Controller {
  async open(event) {
    event.preventDefault()
    const $element = $(this.element)
    const modalType = this.data.get("type")
    const $modal = $element.closest('.container').find(`.${modalType}`)

    switch(modalType) {
      case 'edit-modal':
        this.loadEditModal($modal)
        break
      case 'info-modal':
        await this.loadInfoModal($modal)
        break 
    }

    $modal.find('.button.is-primary').attr('data-url', this.url)
    $modal.addClass('is-active')
  }

  close(event) {
    event.preventDefault()
    $(this.element).removeClass('is-active')
  }

  loadEditModal($modal) {
    const documentName = this.data.get('document-name')
    const projectDate = this.data.get('project-date')
    const expirationDate = this.data.get('expiration-date')

    $modal.find('#name').val(documentName)

    $modal.find('#document_project_date').val(moment(projectDate).format('YYYY-MM-DD'))
    $modal.find('#document_project_date').next().val(moment(projectDate).format('MMMM D, YYYY'))
    
    $modal.find('#expiration_date_reminder').closest('.field').hide()
    $modal.find('#expiration_date_reminder').prop('checked', false)

    if (expirationDate) {
      $modal.find('#document_expiration_date').val(moment(expirationDate).format('YYYY-MM-DD'))
      $modal.find('#document_expiration_date').next().val(moment(expirationDate).format('MMMM D, YYYY')) 
    } else {
      $modal.find('#document_expiration_date').val('')
      $modal.find('#document_expiration_date').next().val('') 
    }
  }

  async loadInfoModal($modal) {
    const { data  } = await axios.get(this.url)
    $modal.find('.modal-card-body').empty().append(data)
    $modal.find('.button.is-primary').attr('href', this.url)
  }

  get url() {
     return this.data.get('url')
  }
}
