import axios from "axios"
import toastr from 'toastr'

const instance = axios.create({
  headers: {
    common: {
      'X-CSRF-Token': $("meta[name='csrf-token']").attr("content"),
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
})

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const { errors } = error.response.data 

  if (Array.isArray(errors)) {
    errors.forEach(error => {
      toastr.error(error)
    })
  }
  
  return Promise.reject(error.response);
});

export default instance
