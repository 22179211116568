import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (this.isMultiSelect) {
      this.multiSelectize()
    } else {
      this.selectize()
    }
  }

  disconnect() {
    this.element.selectize.destroy()
  }

  selectize() {
    $(this.element).selectize({
      allowEmptyOption: true,
    })
  }

  multiSelectize() {
    $(this.element).selectize({
      plugins: ['remove_button'],
      delimiter: ',',
      persist: false,
      create: function(input) {
          return {
              value: input,
              text: input
          }
      }
    })
  }

  get isMultiSelect() {
    return $(this.element).prop('multiple')
  }
}
