import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $body = $(this.element).closest('body')

    if ($body.is('#home')) {
      $body.find('.navbar-start .navbar-item').first().addClass('active')
    } else if (window.location.pathname == '/calendar') {
      $body.find('.navbar-start .navbar-item:nth-child(2)').addClass('active')
    }
  }

  toggle(event) {
    const $target = $(event.currentTarget)
    $target.toggleClass('is-active')
  }

  open(event) {
    const $target = $(event.currentTarget)
    $target.toggleClass('is-active')
    $target.closest('.navbar').find('.navbar-menu').toggleClass('is-active')
  }
}
