import { Controller } from "stimulus"
import moment from "moment"
import axios from '../modules/axios'

export default class extends Controller {
  async connect() {
    const $calendar = $(this.element)
    
    $calendar.fullCalendar({
      header: {
        left: 'prev next today',
        center: 'title',
        right: 'month, agendaWeek, agendaDay'
      },
      selectable: true,
      editable: true,
      eventLimit: true,
      events: async (start, end, timezone, callback) => {
        const events = await this.loadEvents(start, end)
        callback(events)
      },
      select: (start) => {
        this.resetForm($calendar)
        this.loadAddReminderModal($calendar, start)
      },
      eventClick: ({id}) => {
        this.loadViewReminderModal($calendar, id)
      },
    })
  }

  disconnect() {
    $(this.element).fullCalendar('destroy')
  }
  
  async loadEvents(start, end) {
    [start, end] = [moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')]
    const { data: { data } } = await axios.get(`/calendar?start=${start}&end=${end}`)
    const events = data.map(event => ({
      id: event.id,
      title: event.attributes.title,
      start: event.attributes.starting_at,
      end: event.attributes.starting_at,
    }))

    return events
  }

  resetForm($calendar) {  
    const $form = $calendar.find('.modal.add-reminder form')

    $form.trigger("reset")
    $form.find('.selectized').each((index, element) => { 
      element.selectize && element.selectize.clear() 
    })
  }

  loadAddReminderModal($calendar, start) {
    const $modal = $calendar.find('.modal.add-reminder')
    const $startsAtDate = $modal.find('#reminder_starts_at_date')

    $modal.addClass('is-active')

    $startsAtDate.val(moment(start).format('YYYY-MM-DD'))
    $startsAtDate.next().val(moment(start).format('MMMM D, YYYY'))
  }

  async loadViewReminderModal($calendar, reminderId) {
    const $modal = $calendar.find('.modal.view-reminder')
    const { data } = await axios.get(`/reminders/${reminderId}`)
    const $body = $(data).find('.body')
    const title = $(data).find('.title').text()
    
    $modal.find('.modal-card-title').text(title)
    $modal.find('.modal-card-body').empty().append($body)
    $modal.find('.edit-btn').attr('href', `/reminders/${reminderId}/edit`)
    $modal.find('.delete-btn').data('id', reminderId)
    $modal.addClass('is-active')
  }
}
