import { Controller } from "stimulus"
import axios from '../modules/axios'
import toastr from '../modules/toastr'
import flash from '../constants/flash'

export default class extends Controller {
  static targets = ["calendar", "name"]

  async create(event) {
    event.preventDefault()

    if (!this.validate()) return 

    const $target = $(event.target)
    const $calendarTarget = $(this.calendarTarget)
    const $form = $target.closest('.modal').find('form')

    const result = await axios.post('/reminders', $form.serialize())

    const { data: { data } } = result

    if (data) {
      const { id, attributes: { title, starting_at } } = data
  
      $calendarTarget.fullCalendar('renderEvent', {
        id,
        title,
        start: starting_at,
        end: starting_at,
      });
      
      $target.closest('.modal').removeClass('is-active')

      toastr.success(flash.REMINDER_ADDED_TO_CALENDAR)
    }
  }

  delete(event) {
    event.preventDefault()

    const $target = $(event.target)
    const $calendarTarget = $(this.calendarTarget)

    axios.delete(`/reminders/${$target.data('id')}`)

    $calendarTarget.fullCalendar('removeEvents', [$target.data('id')])

    $target.closest('.modal').removeClass('is-active')

    toastr.success(flash.REMINDER_REMOVED_FROM_CALENDAR)
  }

  validate() {
    if (!this.reminderName) return toastr.error(flash.BLANK_REMINDER_NAME)
    if (!this.startingDate) return toastr.error(flash.BLANK_STARTING_DATE)
    if (!this.participants) return toastr.error(flash.NULL_PARTICIPANTS)
    
    return true
  }

  get reminderName() {
    return $(this.element).find('.modal.is-active #reminder_name').val()
  }

  get participants() {
    return $(this.element).find('.modal.is-active #reminder_participant_ids').next().find('.selectize-input').hasClass('has-items')
  }

  get startingDate() {
    return $(this.element).find('.modal.is-active #reminder_starts_at_date').val()
  }
}
