import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).flatpickr({
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
    }); 
  }

  disconnect() {
    $(this.element).flatpickr().destroy()
  }
}
