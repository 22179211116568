import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.maskPhoneNumberField()
  }

  maskPhoneNumberField() {
    $(this.element).usPhoneFormat({
      format: '(xxx) xxx-xxxx',
    }); 
  }
}
