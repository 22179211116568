import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).flatpickr({
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:i K",
    })
  }

  disconnect() {
    $(this.element).flatpickr().destroy()
  }
}
