
import { Controller } from "stimulus"
import axios from "../modules/axios"
import Turbolinks from 'turbolinks'

Turbolinks.scroll = {}

export default class extends Controller {
  connect() {
    const $element = $(this.element)
    const $sideNav = $element.closest('body').find('#menu')

    $sideNav.find('.is-active').removeClass('is-active')
    
    if (location.pathname == "/") {
      Turbolinks.scroll = {}
    } else {
      $sideNav.find(`a[href='${location.pathname}']`).addClass("is-active")
    }

    if (Turbolinks.scroll['top']) {
      $('#menu').scrollTop(Turbolinks.scroll['top'])
    }
  }
  
  setActive(event) {
    const $element = $(this.element)
    const url = $(event.target).attr('href')

    Turbolinks.scroll['top'] = $('#menu').scrollTop()
    
    $element.find(".is-active").removeClass("is-active")
    $element.find(`a[href='${url}']`).addClass("is-active")
  }

  expand(event) {
    this.setActive(event) 

    const $folder = $(event.target)

    if (this._hasChildrenLoaded($folder)) {
      this._hideChildren($folder)
    } else {
      this._addChildren($folder)
    }
  }

  _hasChildrenLoaded = $folder => $folder.find('.icon').hasClass("open")

  _hasCachedChildren = $folder => $folder.next().length > 0

  _addChildren = $folder => {
    $folder.find('.icon').addClass('open')

    if (this._hasCachedChildren($folder)) {
      this._loadCachedChildren($folder)
    } else {
      this._loadChildren($folder)
    }
  }

  _hideChildren = $folder => {
    $folder.find('.icon').removeClass('open')
    $folder.next().hide()
  }

  _loadCachedChildren = $folder => $folder.next().show()

  _loadChildren = async $folder => {
    const url = $folder.attr("href")
    const {data: { data }} = await axios.get(url)
    $folder.after(this._folderList(data))
  }

  _folderList = folders =>
    `<ul>${folders.map(folder => this._folderItem(folder)).join('')}</ul>`

  _folderItem = folder =>
    this._isDocumentLibrary(folder.attributes.type)
      ? this._renderDocumentLibrary(folder)
      : this._renderFolder(folder)

  _isDocumentLibrary = folderType => folderType === "DocumentLibrary"

  _renderDocumentLibrary = folder => 
    `<li>
      <a href="${folder.attributes.url}" data-action="click->sidenav#setActive">
        <i class="icon document-library is-medium"></i>
        ${folder.attributes.name}
      </a>
    </li>`

  _renderFolder = folder => {
    return `<li>
      <a href="${folder.attributes.url}" data-action="click->sidenav#expand">
        <i class="icon ${folder.type} is-medium"></i>
        ${folder.attributes.name}
      </a>
    </li>`
  }
}
